.negative-row {
  background:#fdaaaa;
}

.negative {
  color: red;
}

.positive {
  color: green;
}

.highlight {
  background-color: #fff370;
}